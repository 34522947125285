<template>
    <div>
        <v-row>
            <v-col lg="12">
                <s-crud
                title="Registro de Pallet"
                add
                edit
                remove
                :config="this.config"
                :filter="filter"                
                >
                <template scope="props">
                    <v-container v-if="props.item != null" class="pb-0">
                        <v-row>
                            <v-col>
                                <s-text/>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
                </s-crud>                
            </v-col>
        </v-row>
    </div>
</template>
<script>

export default{
    components:{},
    data:() => ({
        filter:{},
        config:{
            model:{

            },
        },

    }),
    methods: {
        Initialize(){

        }
    },
}
</script>
